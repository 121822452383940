
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { adminStore } from '@/store';
import Page from '../Page.vue';
import Alert from '../../components/ui/Alert.vue';
import SurveySvg from '../../components/svg/SurveySvg.vue';
import MultiStepForm from '../../components/forms/MultiStepForm.vue';
import { MultiStepFormMixin } from '../../mixins/MultiStepFormMixin';
import OrganizationCreateOrgInfo from '../../components/admin/OrganizationCreateOrgInfo.vue';
import OrganizationCreateStaffInfo from '../../components/admin/OrganizationCreateStaffInfo.vue';
import OrganizationCreateLinkTeams from '../../components/admin/OrganizationCreateLinkTeams.vue';
import { OrganizationModel } from '../../models/organization/OrganizationModel';
import { SportName } from '@/../types/enums';
import { OrganizationCreateOptions } from '@/../types/interfaces';
import { OrganizationAdminDashboard } from '@/../types/constants/admin.routes';

import { AuthMixin, MyCoachMixin, BAIconsMixin } from '../../mixins';
import { organizationApi } from '../../api/OrganizationApi';
import { TeamModel } from '@/models/team';
import { country } from '@/pipes';
import { teamApi } from '@/api/TeamApi';

@Component({
	components: {
		Alert,
		MultiStepForm,
		Page,
		SurveySvg,
		OrganizationCreateOrgInfo,
		OrganizationCreateStaffInfo,
		OrganizationCreateLinkTeams,
	},
})
export default class OrganizationCreateForm extends Mixins(MultiStepFormMixin, AuthMixin, MyCoachMixin, BAIconsMixin){

	get IsEdit(): boolean{
		return this.editOrganizationId !== undefined;
	}
	@Prop({ default: undefined }) editOrganizationId?: string;
	@Watch('editOrganizationId') async checkEditOrganization(): Promise<void>{
		if(this.IsEdit){
			await this.setupEditOrganization(this.editOrganizationId);
		}else{
			this.resetForm();
		}
	}
	async mounted(): Promise<void>{
		this.checkEditOrganization();
	}
	editOrganization: OrganizationModel | null = null;
	async setupEditOrganization(orgId: string): Promise<void>{
		this.editOrganization = await organizationApi.findById(orgId, {
			populate: ['teams']
		});
		this.formValue.step1.name = this.editOrganization.name;
		this.formValue.step1.type = this.editOrganization.type;
		this.formValue.step1.city = this.editOrganization.city;
		this.formValue.step1.country = this.editOrganization.country;
		this.formValue.step1.sport = this.editOrganization.sport;
		this.formValue.step1.league = this.editOrganization.league;
		this.formValue.step1.logoUrl = this.editOrganization.logoUrl;

		this.formValue.step2.staffMembers = this.editOrganization.staffMembers;

		this.formValue.step3.teams = this.editOrganization.PopulatedTeams;
	}
	resetForm(): void{
		this.formValue = this.defaultForm();
	}

	get BaseTeam(): TeamModel{
		return new TeamModel().load({
			logoUrl: this.formValue.step1.logoUrl,
			sportId: this.formValue.step1.sport,
			city: this.formValue.step1.city,
			country: this.formValue.step1.country,
		});
	}

	formComplete: boolean = false;
	loading: boolean = false;
	submitting: boolean = false;
	get Loading(): boolean{
		return this.loading || this.submitting;
	}
	steps = 3;
	currentStep = 1;

	get Progress(): number{
		switch(this.currentStep){
		case 1: return 33;
		case 2: return 66;
		case 3: return 95;
		default: return 100;
		}
	}

	defaultForm = (): Record<string, any> => ({
		step1: {
			valid: false,
			name: "",
			type: "",
			location: "",
			sport: "",
			league: "",
			logoUrl: "",
		},
		step2: {
			valid: true,
			staffMembers: [],
			sendStaffInvitations: true,
		},
		step3: {
			valid: true,
			teams: [],
			createTeams: [],
			invitePlayers: false,
			inviteStaff: false,
		}
	});
	formValue = this.defaultForm();

	async finish(): Promise<void>{
		const isEdit = this.editOrganization !== null;
		let organization: OrganizationModel;
		if(isEdit){
			organization = this.editOrganization;
		}
		if(organization === undefined){
			organization = new OrganizationModel();
		}
		const { name, logoUrl, type, city, country, league, sport } = this.formValue.step1;
		organization.name = name;
		organization.type = type;
		organization.city = city;
		organization.country = country;
		organization.league = league;
		organization.sport = sport;
		organization.logoUrl = logoUrl;

		organization.staffMembers = this.formValue.step2.staffMembers;
		const sendStaffInvitations = this.formValue.step2.sendStaffInvitations;

		const createTeamOpts = {
			invitePlayers: this.formValue.step3.invitePlayers,
			inviteStaff: this.formValue.step3.inviteStaff,
		}

		const newTeams = await Promise.all(this.formValue.step3.createTeams.map((team: TeamModel) => {
			return teamApi.insertWithOwnership(team, { params: createTeamOpts });
		}));

		organization.teams = [
			...this.formValue.step3.teams.map((t: TeamModel) => t['_id']),
			...newTeams.map((t: TeamModel) => t['_id']),
		];

		const opts: OrganizationCreateOptions = {
			inviteStaff: sendStaffInvitations,
		};

		if(!isEdit){
			console.groupCollapsed(`OrganizationCreate`);
			console.log("Create Organization", { formValue: this.formValue });
			console.log("OrganizationModel", organization);
			console.log("opts", { opts });
			await adminStore.createOrg({organization, options: opts});
			console.groupEnd();
			this.formComplete = true;
		}else{
			console.groupCollapsed(`OrganizationEdit`);
			organization.id = this.editOrganizationId;
			await adminStore.patchOrg({organization,options: opts});
			console.groupEnd();
			this.formComplete = true;
		}
	}
	cancel(): void{
		this.goToDashboard();
	}

	goToDashboard(): void{
		this.$router.push({ name: OrganizationAdminDashboard });
	}
}
